<template>
  <div id="member-area-container">
    <MemberMenu />
    <div>
      <div class="container-fluid center-max-1320-px p-3">
        <div class="row flex-sm-nowrap justify-content-center">
          <div class="col-md-12 d-flex justify-content-center m-3">
            <div v-if="!cancelSuccessful" class="text-center">
              <h2 class="m-3">Are you sure you want to cancel your subscription?</h2>
              <h4 class="m-3">You won't be able to use {{ instanceInfo?.name }} VPN anymore</h4>
              <button
                id="cancel-button"
                class="header-button m-3"
                type="button"
                color="light"
                @click="cancel"
              >
                Cancel Subscription
              </button>
              <div v-if="cancelFailed" class="row flex-nowrap justify-content-center m-3">
                <div class="col-md-8 alert alert-danger alert-dismissible" role="alert">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                    @click="cancelFailed = false"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <h5 class="alert-heading">We are sorry!</h5>
                  <hr />
                  <p>
                    Due to technical problem your subscription can't be cancelled. Try again or
                    contact our support to cancel your subscription.
                  </p>
                </div>
              </div>
            </div>
            <div v-if="cancelSuccessful" class="row flex-nowrap justify-content-center m-3">
              <div class="col-md-8 alert alert-success" role="alert">
                <h5 class="alert-heading">Cancellation successful</h5>
                <hr />
                <p>Your subscription was cancelled. You will be automatically logged out.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import MemberMenu from '@/app/components/MemberMenu';
import { cancelSubscription } from '@/app/api/user';
import { useUser } from '@/app/use/user';

export default {
  name: 'CancelSubscription',
  components: { MemberMenu },
  inheritAttrs: false,
  props: {
    instanceInfo: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { logout } = useUser();
    const cancelFailed = ref(false);
    const cancelSuccessful = ref(false);
    const cancel = () => {
      cancelSubscription()
        .then(response => {
          if (response.status === 'SUCCESSFUL') {
            cancelSuccessful.value = true;
            cancelFailed.value = false;
            setTimeout(function () {
              logout();
            }, 3000);
          } else {
            cancelFailed.value = true;
            cancelSuccessful.value = false;
          }
        })
        .catch(() => {
          cancelFailed.value = true;
          cancelSuccessful.value = false;
        });
    };
    return { cancel, cancelFailed, cancelSuccessful };
  },
};
</script>
